import { inject, computed } from 'vue';
import { RegInfoModel } from '@/@types';

export default function getBasicData() {
  const $f: any = inject('$f');
  const $store: any = inject('$store');

  const userInfo = computed(() => {
    return $store.state.userInfo || {};
  });

  const regInfo = computed((): RegInfoModel => {
    return $store.state.userInfo.user_info || {};
  });

  const drugstoreName = computed(() => {
    return $f.safeData(regInfo.value, 'drugstoreName', '');
  });

  const userMoney = computed(() => {
    return $f.safeData(userInfo.value, 'user_info.userMoney', 0);
  });

  const userScore = computed(() => {
    return $f.safeData(userInfo.value, 'user_info.userScore', 0);
  });

  const isHigh = computed(() => {
    return $f.safeData(userInfo.value, 'user_info.is_high', 0);
  });

  return {
    drugstoreName,
    userInfo,
    regInfo,
    userMoney,
    userScore,
    isHigh,
  };
}
