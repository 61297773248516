import PageLayout from '@/layouts/PageLayout.vue';
const news = {
  path: 'news',
  redirect: '/news/list',
  component: PageLayout,
  name: '新闻',
  meta: { name: '新闻' },
  children: [
    {
      path: 'list',
      name: '列表',
      component: () => import(/* webpackChunkName: "news" */ '../views/news/NewsList.vue'),
      meta: { name: '列表' },
    },
    {
      path: 'detail',
      name: '详情',
      component: () => import(/* webpackChunkName: "news" */ '../views/news/NewsDetail.vue'),
      meta: { name: '详情' },
    },
  ],
};
export default news;
