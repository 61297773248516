import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a28b059"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-label-box" }
const _hoisted_2 = ["onMouseover"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "category-name" }
const _hoisted_6 = { class: "category-carousel" }
const _hoisted_7 = { class: "category-item-box" }
const _hoisted_8 = { class: "category-item-label flex" }
const _hoisted_9 = { class: "category-item-icon flex-center" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { class: "category-item-li flex-warp" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createElementBlock("div", {
    class: "category-menu flex",
    onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseleave && _ctx.mouseleave(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorys, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'code_' + index,
          class: "category-item",
          onMouseover: ($event: any) => (_ctx.mouseover(index))
        }, [
          _createElementVNode("div", {
            class: "flex",
            onClick: ($event: any) => (_ctx.toGoodsList(item))
          }, [
            _createElementVNode("img", {
              class: "category-img",
              src: item.catImg,
              alt: item.catName
            }, null, 8, _hoisted_4),
            _createElementVNode("span", _hoisted_5, _toDisplayString(item.catName), 1)
          ], 8, _hoisted_3)
        ], 40, _hoisted_2))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createVNode(_component_el_carousel, {
        height: "460px",
        autoplay: false,
        direction: "vertical",
        ref: "CarouselRef",
        loop: false,
        "indicator-position": "none"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorys, (item, index) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, {
              key: index,
              name: index + ''
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: item.catImg,
                        alt: item.catName
                      }, null, 8, _hoisted_10)
                    ]),
                    _createElementVNode("span", null, _toDisplayString(item.catName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catMap[item.catName], (citem, cindex) => {
                      return (_openBlock(), _createElementBlock("a", {
                        href: "javascript:;",
                        key: 'c' + cindex,
                        onClick: ($event: any) => (_ctx.toGoodsList(citem))
                      }, _toDisplayString(citem.catName), 9, _hoisted_12))
                    }), 128))
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["name"]))
          }), 128))
        ]),
        _: 1
      }, 512)
    ], 512), [
      [_vShow, _ctx.isCarousel]
    ])
  ], 32))
}