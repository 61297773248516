import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
import '@/assets/css/init.css';
import Utils from '@/utils/index';
import Router from '@/router/index';
import Store from '@/store/index';
import { ComFuncModel } from '@/@types';
import ReportTip from '@/components/ReportTip.vue';
const app = createApp(App);

const com: ComFuncModel = {
  safeData: Utils.safeData,
  urlParam: Utils.urlParam,
  message: {
    success: Utils.msgSuc,
    error: Utils.msgError,
    warning: Utils.msgWarning,
    info: Utils.msgInfo,
  },
  confirm: Utils.confirm,
  formatTime: Utils.formatTime,
};

app.provide('$f', com);
app.provide('$route', Router);
app.provide('$store', Store);

//const param = route.params.param;

//const token = router.currentRoute.value.query.toekn;
//const hashParams = window.location.hash.substr(1); // Remove the leading '#'
//const hash = window.location.hash.slice(1);

//console.log('token参数是：', params);
app.component('ReportTip', ReportTip);

app
  .use(Store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .mount('#app');
