import PageLayout from '@/layouts/PageLayout.vue';
const activity = {
  path: 'activity',
  redirect: '/anniversary',
  name: '活动',
  component: PageLayout,
  meta: { name: '活动' },
  children: [
    {
      path: 'anniversary',
      name: '周年大礼包',
      component: () =>
        import(/* webpackChunkName: "activity" */ '../views/activity/Anniversary.vue'),
      meta: { name: '周年大礼包', hideBreadcrumb: true },
    },
    {
      path: 'recharge',
      name: '周年庆充值',
      component: () => import(/* webpackChunkName: "activity" */ '../views/activity/Recharge.vue'),
      meta: { name: '周年庆充值', hideBreadcrumb: true },
    },
    {
      path: 'poster',
      name: '活动详情',
      component: () => import(/* webpackChunkName: "activity" */ '../views/activity/Poster.vue'),
      meta: { name: '活动详情', hideBreadcrumb: true },
    },
    {
      path: 'questionnaire',
      name: '问卷调研',
      component: () =>
        import(/* webpackChunkName: "activity" */ '../views/activity/Questionnaire.vue'),
      meta: { name: '问卷调研', hideBreadcrumb: true },
    },
    {
      path: 'seckillArea',
      name: '秒杀限购',
      component: () =>
        import(/* webpackChunkName: "activity" */ '../views/product/SeckillAreaProduct.vue'),
      meta: { name: '秒杀限购', hideBreadcrumb: true },
    },
  ],
};
export default activity;
