import PageLayout from '@/layouts/PageLayout.vue';
const whiteList = {
  path: '',
  redirect: '/article',
  component: PageLayout,
  children: [
    {
      path: 'article',
      name: '资讯页',
      component: () =>
        import(/* webpackChunkName: "white-list" */ '../views/white-list/Article.vue'),
      meta: { name: '资讯页' },
    },
    {
      path: 'drug-display',
      name: '药品展示',
      component: () =>
        import(/* webpackChunkName: "white-list" */ '../views/white-list/DrugDisplay.vue'),
      meta: { name: '药品展示' },
    },
    {
      path: 'report-analysis',
      name: '报告分析',
      component: () =>
        import(/* webpackChunkName: "white-list" */ '../views/white-list/ReportAnalysis.vue'),
      meta: { name: '报告分析' },
    },
    {
      path: 'settled',
      name: '商家入驻',
      component: () =>
        import(/* webpackChunkName: "white-list" */ '../views/white-list/Settled.vue'),
      meta: { name: '商家入驻' },
    },
    {
      path: 'aptitude',
      name: 'aptitude',
      component: () =>
        import(/* webpackChunkName: "white-list" */ '../views/white-list/Aptitude.vue'),
      meta: { name: 'aptitude' },
    },
    {
      path: 'news-detail',
      name: '资讯详情',
      component: () => import(/* webpackChunkName: "white-list" */ '../views/news/NewsDetail.vue'),
      meta: { name: '资讯详情' },
    },
  ],
};
export default whiteList;
